<template>
  <a-spin :spinning="spinning">
  <a-card>
    <!-- 查询表单 -->
    <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">
      <a-row :gutter="8">
        <a-col :md="8" :xl="8" :xxl="8" :sm="24">
          <a-form-model-item label="客户名称">
            <a-input v-model="searchData.cusName" placeholder="客户名称" allowClear></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="8" :xxl="8" :sm="24">
          <a-form-model-item label="联系方式">
            <a-input v-model="searchData.cusPhone" placeholder="联系方式" allowClear></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="8" :xxl="8" :sm="24">
          <a-form-model-item label="物业区域">
            <PCD
              ref="pcd"
              placeholder="请选择区域"
              :province.sync="searchData.province"
              :city.sync="searchData.city"
              :district.sync="searchData.area"
              :provinceId.sync="searchData.provinceId"
              :cityId.sync="searchData.cityId"
              :districtId.sync="searchData.areaId"
            >
            </PCD>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="8" :xxl="8" :sm="24">
          <a-form-model-item label="物业地址">
            <a-input v-model="searchData.detailedAddress" placeholder="物业地址" allowClear></a-input>
          </a-form-model-item>
        </a-col>

        <a-col :md="8" :xl="8" :xxl="8" :sm="24">
          <a-form-model-item label="项目类型">
            <a-select placeholder="请选择" v-model="searchData.orderType" allowClear>
              <a-select-option :value="1">零售</a-select-option>
              <a-select-option :value="2">项目</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="8" :xxl="8" :sm="24">
          <a-form-model-item label="物业类型">
            <a-select placeholder="请选择" v-model="searchData.propertyType" allowClear>
              <a-select-option :value="0">商品房</a-select-option>
              <a-select-option :value="1">自建房</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="8" :xxl="8" :sm="24">
          <a-form-model-item label="户型">
            <a-select placeholder="请选择户型" v-model="searchData.houseType" allowClear>
              <a-select-option :value="item.id" v-for="(item, index) in houseData" :key="index">
                {{ item.title }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>

        <a-col :md="8" :xl="8" :xxl="8" :sm="24">
          <a-form-model-item label="状态">
            <a-select placeholder="请选择" v-model="searchData.type" allowClear>
              <a-select-option :value="''">全部</a-select-option>
              <a-select-option :value="0">机审</a-select-option>
              <a-select-option :value="1">首次申请成功</a-select-option>
              <a-select-option :value="2">二次申请成功</a-select-option>
              <a-select-option :value="3">审核中</a-select-option>
              <a-select-option :value="4">审核拒绝</a-select-option>
              <a-select-option :value="5">签单</a-select-option>
              <a-select-option :value="6">申请成功</a-select-option>
              <a-select-option :value="7">审核成功</a-select-option>
              <a-select-option :value="8">失效</a-select-option>
              <a-select-option :value="9">删除</a-select-option>
              <a-select-option :value="50">无效</a-select-option>
              <a-select-option :value="10">丢单</a-select-option>
              <a-select-option :value="99">申请失败</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="8" :xxl="8" :sm="24">
          <a-form-model-item label="经销商">
            <a-select placeholder="请选择经销商" v-model="searchData.dealerId" @change="onChange" allowClear>
              <a-select-option :value="item.id" v-for="(item, index) in dealerData" :key="index">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="8" :xxl="8" :sm="24" v-if="searchData.dealerId != null">
          <a-form-model-item label="销售员">
            <a-select placeholder="请选择销售员" v-model="searchData.managerId" allowClear>
              <a-select-option :value="item.id" v-for="(item, index) in managerData" :key="index">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="8" :xxl="8" :sm="24" v-if="flagSystem">
          <a-form-model-item label="担当">
            <a-select placeholder="请选择担当" v-model="searchData.userId" allowClear>
              <a-select-option :value="item.id" v-for="(item, index) in userData" :key="index">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>

        <a-col :md="8" :xl="8" :xxl="8" :sm="24">
          <a-form-model-item label="创建时间">
            <DateRange :startTime.sync="searchData.startTime" :endTime.sync="searchData.endTime"></DateRange>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="8" :xxl="8" :sm="24">
          <a-form-model-item label="成交时间">
            <DateRange
              :startTime.sync="searchData.startTransactionTime"
              :endTime.sync="searchData.endTransactionTime"
            ></DateRange>
          </a-form-model-item>
        </a-col>

        <a-col :md="8" :xl="8" :xxl="8" :sm="24">
          <a-form-model-item label="面积">
            <a-input-number
              placeholder=""
              :formatter="(value) => ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
              :parser="(value) => value.replace(/\￥\s?|(,*)/g, '')"
              v-model="searchData.startAcreage"
              :min="1"
              :max="999999"
            />~
            <a-input-number
              placeholder=""
              :formatter="(value) => ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
              :parser="(value) => value.replace(/\￥\s?|(,*)/g, '')"
              v-model="searchData.endAcreage"
              :min="1"
              :max="999999"
            />
          </a-form-model-item>
        </a-col>

        <a-col :md="8" :xl="8" :xxl="8" :sm="24">
          <a-form-model-item label="报价">
            <a-input-number
              placeholder=""
              :formatter="(value) => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
              :parser="(value) => value.replace(/\￥\s?|(,*)/g, '')"
              v-model="searchData.startQuotation"
              :min="1"
              :max="999999"
            />~
            <a-input-number
              placeholder=""
              :formatter="(value) => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
              :parser="(value) => value.replace(/\￥\s?|(,*)/g, '')"
              v-model="searchData.endQuotation"
              :min="1"
              :max="999999"
            />
          </a-form-model-item>
        </a-col>

        <a-col :md="8" :xl="8" :xxl="8" :sm="24">
          <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
            <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
            <a-button type="default" @click="reset()" icon="sync">重置</a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <div>
      <downLoad
        method="get"
        api="/api/dealer/quarters/dealerCustomerOrderLog/excelLogList"
        :params="searchData"
        @downLoadDone="downLoadDone"
        @startDownLoad="startDownLoad"
        name="操作日志.xls"
        >导出操作日志</downLoad
      >
    </div>
    <!-- 数据表格 -->
    <a-table
      :loading="tableLoading"
      :row-selection="rowSelection"
      :rowKey="(record) => record.id"
      :columns="columns"
      :data-source="tableData"
      bordered
      :pagination="page"
      @change="changeTable"
      :customRow="changeTableRow"
      :scroll="{ x: 1500 }"
    >

      <span slot="index" slot-scope="text, row, index">{{ index + 1 }}</span>
      <span slot="orderType" slot-scope="text, row">
         <a-tag v-if="row.orderType == 1" color="#f50">
            零售
          </a-tag>
          <a-tag v-if="row.orderType == 2" color="#108ee9">
            项目
          </a-tag>

      </span>
      <span slot="transactionTime" slot-scope="text, row">
        <span v-if="row.transactionTime == null"> 暂无</span>
        <span v-else>{{row.transactionTime}}</span>
      </span>
      <span slot="propertyType" slot-scope="text, row">
        <a-tag  v-if="row.propertyType == 0" color="green">
          商品房
        </a-tag>
        <a-tag v-if="row.propertyType == 1" color="orange">
         自建房
        </a-tag>
      </span>
      <span slot="picture" slot-scope="text, row">
        <span v-if="row.picture == null">-</span>
        <span v-if="row.picture != null"> <a @click="checkPictureList1(row)">查看图片</a></span>
      </span>
      <span slot="housePicture" slot-scope="text, row">
        <span v-if="row.housePicture == null">-</span>
        <span v-if="row.housePicture != null"> <a @click="checkPictureList(row)">查看图片</a></span>
      </span>
      <span slot="type" slot-scope="text, row">
        <span v-if="row.type == 0"> 机审</span>
        <span v-if="row.type == 1"> 首次申请成功</span>
        <span v-if="row.type == 2">二次申请成功</span>
        <span v-if="row.type == 3"> 审核中</span>
        <span v-if="row.type == 4"> 审核拒绝</span>
        <span v-if="row.type == 5"> 签单</span>
        <span v-if="row.type == 6"> 申请成功</span>
        <span v-if="row.type == 7"> 审核成功</span>
        <span v-if="row.type == 8"> 失效</span>
        <span v-if="row.type == 9"> 删除</span>
        <span v-if="row.type == 10"> 丢单</span>
        <span v-if="row.type == 50"> 无效</span>
        <span v-if="row.type == 99"> 申请失败</span>
      </span>
      <span slot="flagGoods" slot-scope="text, row">
        <span v-if="row.flagGoods == true"> <a @click="checkFlagGoods(row.cusId)">查看商品</a></span>
        <span v-if="row.flagGoods == false">未选择商品</span>
      </span>
      <!-- <span slot="acreage" slot-scope="text,row">
          {{ row.acreage.toFixed(2) }}
        </span> -->
      <span slot="quotation" slot-scope="text,row">
        <span v-if="row.quotation == null">-</span>
          <span v-if="row.quotation != null">{{ row.quotation.toFixed(2) }}</span>
        </span>
    </a-table>

    <a-modal title="查看照片" style="top: 8px" width="40%" v-model="visible" :maskClosable="false" :footer="null">
      <div class="pictureListAll">
        <div v-for="item in pictureListAll" :key="item.id" class="pictureListAll-item">
          <ZoomMedia style="height: 100px;" :value="item"></ZoomMedia>
        </div>
      </div>
    </a-modal>
    <a-modal title="查看商品" style="top: 8px" width="70%" v-model="visibleGoods" :maskClosable="false">
      <a-table
        :rowKey="(record) => record.id"
        :columns="columnsItem"
        :data-source="tableDataList"
        bordered
        :pagination="false"
      >
        <span slot="image" slot-scope="item">
          {{ item }}
          <ZoomMedia style="height: 100px" :value="item"></ZoomMedia>
        </span>
        <span slot="price" slot-scope="text"> {{ text }} </span>
      </a-table>
    </a-modal>
  </a-card>
  </a-spin>
</template>
<script>
import tinymce from "tinymce";

export default {
  data() {
    return {
      spinning:false,
      flagSystem:this.$store.getters.userInfo.flagSystem,//担当下拉
      tableData: [],
      tableDataList: [],
      selectedRowKeys: [],
      selectedRows: [],
      searchData: {},
      managerData: [],
      userData: [],
      dealerData: [],
      houseData: [],
      handle: '',
      pictureListAll: [],
      visibleGoods: false,
      visible: false,
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index',
          width:50,
          align:'center',
          ellipsis:true,
          scopedSlots: { customRender: 'index'}
        },
        {
          title: '客户姓名',
          dataIndex: 'cusName',
          key: 'cusName',
          width: 150,
          align: 'center',
        },
        {
          title: '联系方式',
          dataIndex: 'cusPhone',
          key: 'cusPhone',
          width: 150,
          align: 'center',
        },
        {
          title: '物业地址',
          dataIndex: 'detailedAddress',
          key: 'detailedAddress',
          width: 250,
          align: 'center',
        },
        {
          title: '图片',
          dataIndex: 'picture',
          key: 'picture',
          width: 150,
          align: 'center',
          ellipsis: true,
          scopedSlots: { customRender: 'picture' },
        },

        {
          title: '销售员',
          dataIndex: 'managerName',
          key: 'managerName',
          width: 150,
          align: 'center',
        },
        {
          title: '销售员电话',
          dataIndex: 'managerPhone',
          key: 'managerPhone',
          width: 150,
          align: 'center',
        },
        {
          title: '项目类型',
          dataIndex: 'orderType',
          key: 'orderType',
          width: 150,
          align: 'center',
          scopedSlots: { customRender: 'orderType' },
        },
        {
          title: '物业类型',
          dataIndex: 'propertyType',
          key: 'propertyType',
          width: 150,
          align: 'center',
          ellipsis: true,
          scopedSlots: { customRender: 'propertyType' },
        },
        {
          title: '自建房图片',
          dataIndex: 'housePicture',
          key: 'housePicture',
          width: 150,
          align: 'center',
          ellipsis: true,
          scopedSlots: { customRender: 'housePicture' },
        },
        {
          title: '户型',
          dataIndex: 'doorModel',
          key: 'doorModel',
          width: 150,
          align: 'center',
          ellipsis: true,
        },
        {
          title: '面积（㎡）',
          dataIndex: 'acreage',
          key: 'acreage',
          width: 150,
          align: 'center',
          scopedSlots: { customRender: 'acreage' },
        },
        {
          title: '成交时间',
          dataIndex: 'transactionTime',
          key: 'transactionTime',
          width: 150,
          align: 'center',
          scopedSlots: { customRender: 'transactionTime' },
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          key: 'createTime',
          width: 150,
          align: 'center',
        },
        {
          title: '经销商',
          dataIndex: 'dealerName',
          key: 'dealerName',
          width: 150,
          align: 'center',
          ellipsis: true,
        },
        {
          title: '担当',
          dataIndex: 'userName',
          key: 'userName',
          width: 150,
          align: 'center',
        },

        {
          title: '状态',
          dataIndex: 'type',
          key: 'type',
          width: 150,
          align: 'center',
          scopedSlots: { customRender: 'type' },
        },
        {
          title: '商品名称',
          dataIndex: 'flagGoods',
          key: 'flagGoods',
          width: 150,
          align: 'center',
          ellipsis: true,
          scopedSlots: { customRender: 'flagGoods' },
        },
        {
          title: '报价',
          dataIndex: 'quotation',
          key: 'quotation',
          width: 150,
          align: 'center',
          scopedSlots: { customRender: 'quotation' },
        },

        {
          title: '日志',
          dataIndex: 'log',
          key: 'log',
          width: 150,
          align: 'center',
        },
      ],
      columnsItem: [
        {
          title: '品牌logo',
          dataIndex: 'brandName',
          key: 'brandName',
          /*width:150,*/
          align: 'center',
        },
        {
          title: '商品名称',
          dataIndex: 'goodsName',
          key: 'goodsName',
          /*width:150,*/
          align: 'center',
        },
        {
          title: '商品型号',
          dataIndex: 'goodsStr',
          key: 'goodsStr',
          /*width:150,*/
          align: 'center',
        },
        {
          title: '商品价格',
          dataIndex: 'price',
          key: 'price',
          /*width:150,*/
          align: 'center',
          scopedSlots: { customRender: 'price' },
        },
        {
          title: '商品图片',
          dataIndex: 'image',
          key: 'image',
          /*width:150,*/
          align: 'center',
          scopedSlots: { customRender: 'image' },
        },
      ],
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
  },
  methods: {
    startDownLoad(){
      this.spinning = true
    },
    downLoadDone (){
      this.spinning = false
    },
    onChange(e) {
      console.info(e)
      this.axios.get(`/api/base/system/dealer/manager/managerListByDealerId/`+ e ).then((res) => {
        if (res.code == 200) {
          this.managerData = res.body
        }
      })
    },
    // 商品集合
    checkFlagGoods(cusId) {
      this.visibleGoods = true
      this.axios.get(`/api/dealer/quarters/dealerCustomerOrderLog/getGoodsByCusId/${cusId}`).then((res) => {
        this.tableDataList = res.body
      })
    },
    // 图片接口
    checkPictureList(row) {
        this.visible = true
        this.pictureListAll = row.housePicture.split(',')
    },
    checkPictureList1(row){
      this.visible = true
      this.pictureListAll = row.picture.split(',')
    },
    getData() {
      this.tableLoading = true
      this.selectedRowKeys = []
      this.selectedRows = []
      this.axios
        .get('/api/dealer/quarters/dealerCustomerOrderLog/list', {
          params: {
            pageNumber: this.page.current,
            pageSize: this.page.pageSize,
            ...this.searchData,
          },
        })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))
      this.axios.get(`/api/dealer/dealer/dealerInfo/dealerListForFirm`).then((res) => {
        if (res.code == 200) {
          this.dealerData = res.body
        }
      })
      this.axios.get(`/api/base/system/dealer/manager/managerListForFirm`).then((res) => {
        if (res.code == 200) {
          this.userData = res.body
        }
      })
      this.axios.get('/api/base/system/dictionary/selectByCode/'+ '房型').then(res => {  // 获取字典房型
        if (res.code == 200) {
          this.houseData = res.body
        }
      })
    },
    /**
     * 筛选查询
     */
    getDataFilter() {
      this.page.current = 1
      this.page.pageSize = 10
      this.getData()
    },
    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      this.getData()
    },
    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },

    // toHanlder(name) {
    //   if (name == 'add') {
    //     this.$refs.eventsModal.showInfo({}, 'add')
    //     return
    //   }
    //   if (this.selectedRows.length == 0) {
    //     this.$notification.info({
    //       message: '请选择一条记录',
    //     })
    //     return
    //   }
    //   switch (name) {
    //     case 'edit':
    //       this.$refs.eventsModal.showInfo(this.selectedRows[0], 'edit')
    //       break
    //     case 'del':
    //       this.toDelete(this.selectedRows[0])
    //       break
    //     // case 'signup':
    //     //   this.$refs.eventsSignUpModal.showInfo(this.selectedRowKeys[0])
    //     //   break
    //   }
    // },
    // toDelete(row) {
    //   this.$confirm({
    //     title: '警告',
    //     content: `真的删除吗?`,
    //     okType: 'danger',
    //     onOk: () => {
    //         console.log(row.id)
    //       this.axios
    //         .post(`/api/agent/system/partnerMessage/del?id=${row.id}`)
    //         .then((res) => {
    //           this.$notification.success({
    //             message: "删除成功",
    //           })
    //           this.toSearch()
    //         })
    //         .catch((err) => {})
    //     },
    //   })
    // },
  },
  created() {
    this.getData()
  },
}
</script>
<style lang="less" scoped>
.pictureListAll {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  .pictureListAll-item {
    flex:1;
    img {
      height: 100%;
      width: 100%;
    }
  }
}
</style>